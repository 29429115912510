<template>
  <button
    class="button primary contacts-button"
    :class="size"
    :id="id"
    @click="showModal = true"
  >
    <slot><span class="button-content">
      {{ btnContent }}
      <span class="icon"
        ><img
          v-svg-inline
          :data-src="require('@/assets/icons/rarr-icon.svg')"
          alt=""
      /></span>
      </span>
    </slot>
  </button>
  <ModalContact
    v-if="showModal"
    @close="showModal = false"
    :startProjectModal="startProject"
    :moreTextGetInTouch="description"
    :formMessage="formMessage"
    ><h2 v-if="type">Start a Project</h2></ModalContact
  >
</template>
<script>
import ModalContact from "./ModalContact.vue";

export default {
  components: { ModalContact },
  props: {
    size: String,
    type: String,
    desc: String,
    id: String,
    formMessage: String,
    btnContent: {
      type: String,
      default: "Get in touch",
    },
  },
  data() {
    return {
      showModal: false,
      startProject: this.type,
      description: this.desc,
    };
  },
  mounted() {

    let url = new URL(window.location.href);
    if (this.id == 'show-start-project-modal' && url.searchParams.get('start-project') !==  null) {
      this.showModal=true;
    }

  },
};
</script>
<style lang="scss">
@import "@/scss/_variables.scss";
@import "include-media/dist/_include-media.scss";
.contacts-button{
  .button-content{
     display: flex;
     align-items: center;
  }
}
</style>
